<script>
import {
  Line
} from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      datacollection: {
        labels: ["2000", "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018"],
        datasets: [{
            label: '# of Votes',
            data: [60, 50, 55, 45, 58, 80, 60, 59, 70, 65, 24, 5, 15, 10, 30, 25, 35, 50, 40, 45],
            backgroundColor: [
                'rgba(2, 171, 254, 0.2)',
            ],
            pointBackgroundColor: 'rgba(255, 255, 255,1)',
            pointBorderColor: 'rgba(255, 255, 255,1)',
            borderColor: [
                'rgba(2, 171, 254,1)',
            ],
            borderWidth: 1,
            fill: true, // 3: no fill
        }]
      },
      options: {
        scales: {
          yAxes: [{
            display: false,
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: false,
            }
          }],
          xAxes: [{
            display: false,
          }],
        },
        legend: {
          display: false
        },
        plugins: {
          filler: {
            propagate: true
          },
        },
        elements: {
          line: {
            tension: 0
          },
          tooltips: {
            backgroundColor: 'rgba(31, 59, 179, 1)',
          }
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
