<script>
import {
  Line
} from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      datacollection: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
        datasets: [{
          label: 'first label',
          data: [20, 25, 35, 45, 35, 40, 46, 44],
          backgroundColor: [
            'rgba(2, 171, 254, 1)',
            'rgba(2, 171, 254, 1)',
            'rgba(2, 171, 254, 1)',
            'rgba(2, 171, 254, 1)',
          ],
          borderColor: [
            'rgba(35, 175, 71, 1)'
          ],
          borderWidth: 4,
          fill: false,
          pointBorderWidth: 4,
          pointRadius: [0, 0, 0, 5, 0],
          pointHoverRadius: [0, 0, 0, 5, 0],
          pointBackgroundColor: ['rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)'],
          pointBorderColor: ['rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)'],
        },
        {
          label: 'second label',
          data: [30, 24, 28, 35, 40, 35, 32, 38],
          borderColor: [
              'rgba(31, 59, 179, 1)',
          ],
          borderWidth: 4,
          fill: false,
          pointBorderWidth: 4,
          pointRadius: [0, 0, 0, 0, 0],
          pointHoverRadius: [0, 0, 0, 0, 0],
          pointBackgroundColor: ['rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)'],
          pointBorderColor: ['rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)'],
        },
        {
          label: 'third label',
          data: [35, 30, 36, 45, 35, 44, 48, 53],
          borderColor: [
              'rgba(232, 233, 241,  .48)',
          ],
          backgroundColor: [
              'rgba(232,233,241, .48)',
          ],
          borderWidth: 4,
          fill: true,
          pointBorderWidth: 4,
          pointRadius: [0, 0, 0, 0, 0],
          pointHoverRadius: [0, 0, 0, 0, 0],
          pointBackgroundColor: ['rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)', 'rgba(35, 175, 71,1)'],
          pointBorderColor: ['rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)'],
        }
        ],
      },
      options: {
        scales: {
          yAxes: [{
            display: false
          }],
          xAxes: [{
            position: 'bottom',
            gridLines: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              beginAtZero: false,
              stepSize: 50
            }
          }],

        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 0
          }
        },
        tooltips: {
          backgroundColor: 'rgba(2, 171, 254, 1)',
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
