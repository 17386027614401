<script>
import {
  Doughnut
} from 'vue-chartjs'

export default {
  extends: Doughnut,
  data () {
    return {
      datacollection: {
        datasets: [{
          data: [26, 74],
          backgroundColor: [
            '#3fadf8',
            '#eeeeee',
          ],
          borderColor: [
            '#3fadf8',
            '#eeeeee',
          ]
        }]
      },
      options: {
        responsive: true,
        cutoutPercentage: 90,
        legend: {
					display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
