<script>
import {
  Line
} from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      datacollection: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May"],
        datasets: [{
					label: '# of Votes',
					data: [0, 30, 20, 70, 75],
					backgroundColor: "rgba(228, 243, 240, 0.5)",
					borderColor: [
							'rgba(35, 175, 71,1)',
					],
					borderWidth: 3,
					fill: true, // 3: no fill
					pointBorderWidth: 4,
					pointRadius: [0, 0, 0, 10, 0],
					pointHoverRadius: [0, 0, 0, 10, 0],
					pointBackgroundColor: ['rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)', 'rgba(255, 255, 255,1)'],
					pointBorderColor: ['rgba(35, 175, 71,0.3)', 'rgba(35, 175, 71,0.3)', 'rgba(35, 175, 71,0.3)', 'rgba(35, 175, 71,0.3)'],
			}]
      },
      options: {
        scales: {
                yAxes: [{
                    gridLines: {
                        display: false,
                        drawBorder: false,
                    },
                    ticks: {
                        beginAtZero: false,
                    }
                }],
				},
				legend: {
						display: false
				},
				elements: {
						line: {
								tension: 0.4,
						}
				},
				tooltips: {
						backgroundColor: 'rgba(31, 59, 179, 1)',
				}
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
