<script>
import {
  Bar
} from 'vue-chartjs'

export default {
  extends: Bar,
  data () {
    return {
      datacollection: {
        labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
        datasets: [{
					data: [39, 39, 48, 28, 38],
					backgroundColor: '#fff',
					pointRadius: [0, 0, 0, 0, 0],
					pointHoverRadius: [0, 0, 0, 0, 0],
				}]
      },
      options: {
        scales: {
					responsive: true,
					maintainAspectRatio: true,
					yAxes: [{
						stacked: true,
						display: false,
						gridLines: {
							color: 'rgba(0, 0, 0, 0.03)',
						}
					}],
					xAxes: [{
						stacked: true,
						display: false,
						barPercentage: 0.4,
						gridLines: {
							display: false,
						}
					}]
        },
        legend: {
					display: false
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
