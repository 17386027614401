<script>
import {
  HorizontalBar
} from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  data () {
    return {
      datacollection: {
        labels: ["Jan", "Feb", "Mar", "Apr"],
        datasets: [{
                label: 'Offline purchases',
                data: [41676, 40776, 41676, 39676],
                backgroundColor: [
                    'rgba(31, 59, 179, 1)',
                    'rgba(31, 59, 179, 1)',
                    'rgba(31, 59, 179, 1)',
                    'rgba(31, 59, 179, 1)',
                ],
                borderColor: [
                    'rgba(31, 59, 179, 1)',
                    'rgba(31, 59, 179, 1)',
                    'rgba(31, 59, 179, 1)',
                    'rgba(31, 59, 179, 1)',
                ],
                borderWidth: 2,
                fill: false
            },
            {
                label: 'Online purchases',
                data: [40982, 50982, 31982, 48982],
                backgroundColor: [
                    'rgba(35, 175, 71, 1)',
                    'rgba(35, 175, 71, 1)',
                    'rgba(35, 175, 71, 1)',
                    'rgba(35, 175, 71, 1)',
                ],
                borderColor: [
                    'rgba(35, 175, 71, 1)',
                    'rgba(35, 175, 71, 1)',
                    'rgba(35, 175, 71, 1)',
                    'rgba(35, 175, 71, 1)',
                ],
                borderWidth: 2,
                fill: false
            },
            {
                label: 'Offline sales',
                data: [57545, 37545, 87545, 48982],
                backgroundColor: [
                    'rgba(2, 171, 254, 1)',
                    'rgba(2, 171, 254, 1)',
                    'rgba(2, 171, 254, 1)',
                    'rgba(2, 171, 254, 1)',
                ],
                borderColor: [
                    'rgba(2, 171, 254, 1)',
                    'rgba(2, 171, 254, 1)',
                    'rgba(2, 171, 254, 1)',
                    'rgba(2, 171, 254, 1)',
                ],
                borderWidth: 2,
                fill: false
            },
            {
                label: 'Online sales',
                data: [47545, 27545, 48982, 97545],
                backgroundColor: [
                    'rgba(224, 224, 224, 1)',
                    'rgba(224, 224, 224, 1)',
                    'rgba(224, 224, 224, 1)',
                    'rgba(224, 224, 224, 1)',
                ],
                borderColor: [
                    'rgba(224, 224, 224, 1)',
                    'rgba(224, 224, 224, 1)',
                    'rgba(224, 224, 224, 1)',
                    'rgba(224, 224, 224, 1)',
                ],
                borderWidth: 2,
                fill: false
            }
        ],
      },
      options: {
         scales: {
            xAxes: [{
              position: 'bottom',
              display: true,
              gridLines: {
                display: false,
                drawBorder: true,
              },
              ticks: {
                display: false //this will remove only the label
              }
            }],
            yAxes: [{
              display: false,
              gridLines: {
                drawBorder: true,
                display: false,
              },
            }]
        },
        legend: {
            display: false
        },
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
