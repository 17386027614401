<template>
  <section class="dashboard">
    <div class="row">
      <div class="col-sm-6 mb-4 mb-xl-0">
        <h3>Congrats Edwin!</h3>
        <h6 class="font-weight-normal mb-0 text-muted">You have done 57.6% more sales today.</h6>
      </div>
      <div class="col-sm-6">
        <div class="d-flex align-items-center justify-content-md-end">
          <div class="border-right-dark pr-4 mb-3 mb-xl-0 d-xl-block d-none">
            <p class="text-muted">Today</p>
            <h6 class="font-weight-medium text-dark mb-0">23 Aug 2019</h6>
          </div>
          <div class="pr-4 pl-4 mb-3 mb-xl-0 d-xl-block d-none">
            <p class="text-muted">Category</p>
            <h6 class="font-weight-medium text-dark mb-0">All Categories</h6>
          </div>
          <div class="pr-1 mb-3 mb-xl-0">
            <button type="button" class="btn btn-success btn-icon mr-2"><i class="mdi mdi-filter-variant"></i></button>
          </div>
          <div class="pr-1 mb-3 mb-xl-0">
            <button type="button" class="btn btn-success btn-icon mr-2"><i class="mdi mdi-refresh"></i></button>
          </div>
          <div class="mb-3 mb-xl-0">
            <b-dropdown id="dropdown-right" text="14 Aug 2019" variant="success" class="btn-dropdown-header p-0">
              <b-dropdown-item href="#">2015</b-dropdown-item>
              <b-dropdown-item href="#">2016</b-dropdown-item>
              <b-dropdown-item href="#">2017</b-dropdown-item>
              <b-dropdown-item href="#">2018</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="page-header-tab mt-xl-4">
            <div class="col-12 pl-0 pr-0">
              <div class="row ">
                <div class="col-12 col-sm-6 mb-xs-4  pt-2 pb-2 mb-xl-0">
                  <ul class="nav nav-tabs tab-transparent" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="overview-tab" data-toggle="tab" href="#" role="tab" aria-controls="overview" aria-selected="true">Overview</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="users-tab" data-toggle="tab" href="#" role="tab" aria-controls="users" aria-selected="false">Users</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="returns-tab" data-toggle="tab" href="#" role="tab" aria-controls="returns" aria-selected="false">Returns</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="more-tab" data-toggle="tab" href="#" role="tab" aria-controls="more" aria-selected="false">More</a>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-sm-6 mb-xs-4 mb-xl-0 pt-2 pb-2 text-md-right d-none d-md-block">
                  <div class="d-inline-flex">
                    <button class="btn d-flex align-items-center">
                      <i class="mdi mdi-download mr-1"></i>
                      <span class="text-left font-weight-medium">
                        Download report
                      </span>
                    </button>
                    <button class="btn d-flex align-items-center">
                      <i class="mdi mdi-file-pdf  mr-1"></i>
                      <span class="font-weight-medium text-left">
                        Export
                      </span>
                    </button>
                    <button class="btn d-flex align-items-center pr-0">
                      <i class="mdi mdi-email-outline  mr-1"></i>
                      <span class="text-left font-weight-medium">
                       Send as Email
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="tab-content tab-transparent-content pb-0">
          <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Sales</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <VueSlickCarousel :arrows="true" :dots="false" :autoplay="true" class="dashboard-carousel">
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 27632</h2>
                            <h3 class="text-danger">-2.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Oct
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 37662</h2>
                            <h3 class="text-success">+2.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Nov
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 12312</h2>
                            <h3 class="text-danger">-1.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Dec
                            </span>
                          </button>
                        </div>
                      </div>
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Purchases</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <VueSlickCarousel :arrows="true" :dots="false" :autoplaySpeed="2700" :autoplay="true" class="dashboard-carousel">
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 12582</h2>
                            <h3 class="text-success">+0.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Oct
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 52351</h2>
                            <h3 class="text-danger">-3.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Nov
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 22312</h2>
                            <h3 class="text-success">+3.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Dec
                            </span>
                          </button>
                        </div>
                      </div>
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Returns</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <VueSlickCarousel :arrows="true" :dots="false" :autoplaySpeed="2800" :autoplay="true" class="dashboard-carousel">
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 22582</h2>
                            <h3 class="text-danger">-0.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Oct
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 32151</h2>
                            <h3 class="text-danger">-3.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Nov
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 31221</h2>
                            <h3 class="text-success">+2.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Dec
                            </span>
                          </button>
                        </div>
                      </div>
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 col-md-6 col-xl-3 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Marketing</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <VueSlickCarousel :arrows="true" :dots="false" :autoplaySpeed="2600" :autoplay="true" class="dashboard-carousel">
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 27272</h2>
                            <h3 class="text-success">+1.13%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Oct
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 12171</h2>
                            <h3 class="text-danger">-1.3%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Nov
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div class="carousel-item active">
                          <div class="d-flex flex-wrap align-items-baseline">
                            <h2 class="mr-3">$ 21211</h2>
                            <h3 class="text-success">+3.1%</h3>
                          </div>
                          <div class="mb-3">
                            <p class="text-muted font-weight-bold text-small">North Ludwig <span class=" font-weight-normal">($2643M last month)</span></p>
                          </div>
                          <button class="btn btn-outline-secondary btn-sm btn-icon-text d-flex align-items-center">
                            <i class="mdi mdi-calendar mr-1"></i>
                            <span class="text-left">
                              Dec
                            </span>
                          </button>
                        </div>
                      </div>
                    </VueSlickCarousel>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-xl-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <div>
                        <h4 class="card-title mb-3">Revenue overview</h4>
                      </div>
                      <div>
                        <div class="d-flex align-items-center">
                          <div class="dropdown mr-2 d-none d-md-block">
                            <b-dropdown id="dropdown-right" text="14 Aug 2019" variant="secondary" class="btn-dropdown-secondary">
                              <b-dropdown-item href="#">2015</b-dropdown-item>
                              <b-dropdown-item href="#">2016</b-dropdown-item>
                              <b-dropdown-item href="#">2017</b-dropdown-item>
                              <b-dropdown-item href="#">2018</b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="dropdown dropleft card-menu-dropdown">
                            <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                              <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                                <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                                </button>
                              </template>
                              <b-dropdown-item href="#">Action</b-dropdown-item>
                              <b-dropdown-item href="#">Another action</b-dropdown-item>
                              <b-dropdown-item href="#">Something else here</b-dropdown-item>
                            </b-nav-item-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="text-muted">Customers who have upgraded the level of your products or service</p>
                    <div class="mt-4 mb-4 d-sm-flex">
                      <div id="legendContainer" class="mb-4 mr-4 legendContainer col-md-4 pl-0 pr-0">
                        <div class="row">
                          <div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0">
                            <div class="row mb-3 align-items-center">
                              <div class="col-sm-12">
                                <h6>This year</h6>
                              </div>
                              <div class="col-md-2">
                                <span class="legend-label bg-primary"></span>
                              </div>
                              <div class="col-md-9 pl-md-2">
                                <h2 class="mb-0"> 56</h2>
                              </div>
                              <div class="col-sm-12">
                                <p class="text-small text-muted">57% Total</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0">
                            <div class="row mb-3 align-items-center">
                              <div class="col-sm-12">
                                <h6>Past year</h6>
                              </div>
                              <div class="col-md-2">
                                <span class="legend-label bg-success"></span>
                              </div>
                              <div class="col-md-9 pl-md-2">
                                <h2 class="mb-0"> 26</h2>
                              </div>
                              <div class="col-sm-12">
                                <p class="text-small text-muted">27% Total</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 pl-0 pr-0">
                        <h6>Summary</h6>
                        <p class="text-muted">A comparison of people who mark themeselves of their interest based from the date range given above.</p>
                      </div>
                    </div>
                    <div class="row mt-1 d-sm-flex">
                      <div class="col-12">
                        <revenueOverview :height="200"></revenueOverview>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 d-flex flex-column">
                <div class="row flex-grow">
                  <div class="col-12 col-lg-4 col-lg-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex flex-wrap justify-content-between">
                          <h4 class="card-title mb-3">Sales top charts</h4>
                          <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                            <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                              <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                              </button>
                            </template>
                            <b-dropdown-item href="#">Action</b-dropdown-item>
                            <b-dropdown-item href="#">Another action</b-dropdown-item>
                            <b-dropdown-item href="#">Something else here</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12">
                                <p class="text-muted mb-4 text-left">Customers who have upgraded the level of your products or service</p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <salesTop :height="180"></salesTop>
                              </div>
                            </div>
                            <div class="row mt-5 d-none d-sm-flex">
                              <div class="col-12 col-sm-6 text-left">
                                <h1>891</h1>
                                <p class="font-weight-normal text-muted">North Ludwig</p>
                              </div>
                              <div class="col-12 col-sm-6 text-left">
                                <h1>227</h1>
                                <p class="font-weight-normal text-muted">North Ludwig</p>
                              </div>
                            </div>
                            <div class="row pt-3 mt-md-4">
                              <div class="col mb-2">
                                <div class="d-flex sales-top-chart-legend align-items-center">
                                  <div class="bg-info p-3 mr-3 mr-lg-0 mr-lg-3 mb-md-2 mb-lg-0">
                                    <budgetBar :width="20" :height="20"></budgetBar>
                                  </div>
                                  <div class="wrapper d-flex flex-column justify-content-center">
                                    <p class="font-weight-medium text-muted">Budget</p>
                                    <h3 class="font-weight-medium mb-0">$12,783</h3>
                                  </div>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex sales-top-chart-legend align-items-center">
                                  <div class="bg-success p-3 mr-3 mr-lg-0 mr-lg-3 mb-md-2 mb-lg-0">
                                    <salesBar :width="20" :height="20"></salesBar>
                                  </div>
                                  <div class="wrapper d-flex flex-column justify-content-center">
                                    <p class="font-weight-medium text-muted">Sales</p>
                                    <h3 class="font-weight-medium mb-0">9,065</h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 mt-4">
                                <p class="text-muted text-left">Lorem Ipsum is simply dummy text of the printing</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 d-flex flex-column">
                <div class="row flex-grow">
                  <div class="col-md-6 col-lg-12 grid-margin stretch-card">
                    <div class="card bg-primary">
                      <div class="card-body pb-0">
                        <div class="d-flex flex-wrap justify-content-between">
                          <h4 class="card-title text-white">Marketing</h4>
                          <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                            <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                              <button class="btn p-0 text-white" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                              </button>
                            </template>
                            <b-dropdown-item href="#">Action</b-dropdown-item>
                            <b-dropdown-item href="#">Another action</b-dropdown-item>
                            <b-dropdown-item href="#">Something else here</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>
                        <div class="d-flex flex-wrap align-items-center justify-content-between">
                          <div>
                            <h6 class="text-white">Past year</h6>
                            <h1 class="text-white">46360</h1>
                          </div>
                          <button class="btn btn-outline-primary btn-fw border ml-xl-4 text-white mb-4">View more</button>
                        </div>
                      </div>
                      <div class="card-body p-0">
                        <div>
                         <marketing :height="180"></marketing>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex flex-wrap align-items-baseline justify-content-between">
                          <h4 class="card-title">Activity</h4>
                          <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                            <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                              <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                              </button>
                            </template>
                            <b-dropdown-item href="#">Action</b-dropdown-item>
                            <b-dropdown-item href="#">Another action</b-dropdown-item>
                            <b-dropdown-item href="#">Something else here</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>
                        <div>
                          <ul class="solid-icon-list">
                            <li>
                              <h5>Offline purchases</h5>
                              <h6 class="text-muted font-weight-normal">A comparison of people who mark
                                <span class="mt-3">30 Min ago</span>
                              </h6>
                            </li>
                            <li>
                              <h5>Offline purchases</h5>
                              <h6 class="text-muted font-weight-normal">A comparison of people who mark
                                <span class="mt-3">30 Min ago</span>
                              </h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 d-flex flex-column">
                <div class="row flex-grow">
                  <div class="col-12 col-md-12 col-lg-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex flex-wrap justify-content-between">
                          <h4 class="card-title mb-3">Purchases in detail</h4>
                          <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                            <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                              <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                              </button>
                            </template>
                            <b-dropdown-item href="#">Action</b-dropdown-item>
                            <b-dropdown-item href="#">Another action</b-dropdown-item>
                            <b-dropdown-item href="#">Something else here</b-dropdown-item>
                          </b-nav-item-dropdown>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="chartjs-legend mt-4" id="chart-legends-purchase">
                              <div class="row">
                                <div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0">
                                  <div class="row mb-3 align-items-center">
                                    <div class="col-md-2">
                                      <span class="legend-label bg-primary"></span>
                                    </div>
                                    <div class="col-md-9 pl-md-2">
                                      <h3 class="mb-0">$ 41676</h3>
                                    </div>
                                    <div class="col-sm-12">
                                      <p class="text-muted">Offline purchases</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0">
                                  <div class="row mb-3 align-items-center">
                                    <div class="col-md-2">
                                      <span class="legend-label bg-success"></span>
                                    </div>
                                    <div class="col-md-9 pl-md-2">
                                      <h3 class="mb-0">$ 50982</h3>
                                    </div>
                                    <div class="col-sm-12">
                                      <p class="text-muted">Online purchases</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0">
                                  <div class="row mb-3 align-items-center">
                                    <div class="col-md-2">
                                      <span class="legend-label bg-info"></span>
                                    </div>
                                    <div class="col-md-9 pl-md-2">
                                      <h3 class="mb-0">$ 87545</h3>
                                    </div>
                                    <div class="col-sm-12">
                                      <p class="text-muted">Offline sales</p>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-6 col mr-3 ml-3 ml-sm-0 mr-sm-0 pr-md-0">
                                  <div class="row mb-3 align-items-center">
                                    <div class="col-md-2">
                                      <span class="legend-label bg-light"></span>
                                    </div>
                                    <div class="col-md-9 pl-md-2">
                                      <h3 class="mb-0">$ 97545</h3>
                                    </div>
                                    <div class="col-sm-12">
                                      <p class="text-muted">Online sales</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <purchaseDetails :height="192"></purchaseDetails>
                              </div>
                            </div>
                            <div class="row pt-3 mt-md-4">
                              <div class="col">
                                <div class="d-flex purchase-detail-legend align-items-center">
                                  <div class="circle-chart-wrap p-2">
                                    <doughnutChart></doughnutChart>
                                  </div>
                                    <div>
                                      <p class="font-weight-medium text-muted text-small">Sessions</p>
                                      <h3 class="font-weight-medium  mb-0">26.80%</h3>
                                    </div>
                                </div>
                              </div>
                              <div class="col">
                                <div class="d-flex purchase-detail-legend align-items-center">
                                     <div class="circle-chart-wrap p-2">
                                        <doughnutChartUsers></doughnutChartUsers>
                                      </div>
                                    <div>
                                      <p class="font-weight-medium text-muted text-small">Users</p>
                                      <h3 class="font-weight-medium  mb-0">56.80%</h3>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12 mt-4">
                                <p class="text-muted text-left">Lorem Ipsum is simply dummy text of the printing</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-4 col-xl-4 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">To do</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <div>
                      <todo-list />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8 col-xl-8 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Sales</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <p class="text-muted">People who have a ticket reservation of the event is automatically mark as interested.</p>
                    <div class="border pt-2 pb-2 mt-4 mb-3 border-radius-widget">
                      <ul class="d-md-flex flex-wrap align-items-baseline justify-content-center list-unstyled text-center mb-0 sales-legend">
                        <li class="border-right-sm">
                          <h6 class="font-weight-normal">Total</h6>
                          <h2 class="text-primary">2584</h2>
                          <p class="text-primary pl-md-4 pr-md-4">56.04 % Total</p>
                        </li>
                        <li class="border-right-sm">
                          <h6 class="font-weight-normal">This Year</h6>
                          <h2 class="text-primary pl-md-3 pr-3">46360</h2>
                          <p class="text-primary pl-3 pr-3">32.68 % Total</p>
                        </li>
                        <li class="border-right-sm">
                          <h6 class="font-weight-normal">Past year</h6>
                          <h2 class="text-primary">46360</h2>
                          <p class="text-primary">97.32% Total</p>
                        </li>
                        <li class="pb-2 pt-2 pl-4 pr-4">
                          <h6 class="font-weight-normal">Difference</h6>
                          <h2 class="text-primary">93819</h2>
                          <p class="text-primary">76.47% Total</p>
                        </li>
                      </ul>
                    </div>
                    <div class="row mt-1 d-sm-flex">
                      <div class="col-12">
                        <salesArea :height="200"></salesArea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 grid-margin">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Orders</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <div class="table-responsive">
                      <table class="table center-aligned-table">
                        <thead>
                          <tr>
                            <th class="pl-0"> 
                              <div class="form-check mb-0">
                                <label class="form-check-label">
                                <input type="checkbox" class="form-check-input">
                                <i class="input-helper"></i></label>
                              </div>
                            </th>
                            <th>Order ID</th>
                            <th>Customer</th>
                            <th>Product</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="pl-0">
                              <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" class="form-check-input">
                                <i class="input-helper"></i></label>
                              </div>
                            </td>
                            <td>
                              <img src="@/assets/images/orders/order-1.jpg" alt="image" class="img-rect mr-2">
                            </td>
                            <td>
                              <div class="text-dark font-weight-medium">6547-3DESC9835</div>
                            </td>
                            <td>Nike Hazard</td>
                            <td>18 May 2019</td>
                            <td><label class="badge badge-success">Completed</label></td>
                            <td>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-dots-horizontal"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-grease-pencil"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-delete"></i></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0">
                              <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" class="form-check-input">
                                <i class="input-helper"></i></label>
                              </div>
                            </td>
                            <td>
                              <img src="@/assets/images/orders/order-2.jpg" alt="image" class="img-rect mr-2">
                            </td>
                            <td>
                              <div class="text-dark font-weight-medium">6547-3DESC9835</div>
                            </td>
                            <td>iPhone X</td>
                            <td>13 Aug 2019</td>
                            <td><label class="badge badge-warning">Delayed</label></td>
                            <td>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-dots-horizontal"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-grease-pencil"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-delete"></i></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0">
                              <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" class="form-check-input">
                                <i class="input-helper"></i></label>
                              </div>
                            </td>
                            <td>
                              <img src="@/assets/images/orders/order-3.jpg" alt="image" class="img-rect mr-2">
                            </td>
                            <td>
                              <div class="text-dark font-weight-medium">6547-3DESC9835</div>
                            </td>
                            <td>Gucci all black</td>
                            <td>18 Oct 2019</td>
                            <td><label class="badge badge-danger">Cancelled</label></td>
                            <td>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-dots-horizontal"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-grease-pencil"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-delete"></i></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0">
                              <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" class="form-check-input">
                                <i class="input-helper"></i></label>
                              </div>
                            </td>
                            <td>
                              <img src="@/assets/images/orders/order-4.jpg" alt="image" class="img-rect mr-2">
                            </td>
                            <td>
                              <div class="text-dark font-weight-medium">6547-3DESC9835</div>
                            </td>
                            <td>Vitality shot Mango</td>
                            <td>16 Sep 2019</td>
                            <td><label class="badge badge-success">Completed</label></td>
                            <td>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-dots-horizontal"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-grease-pencil"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-delete"></i></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="pl-0">
                              <div class="form-check">
                                <label class="form-check-label">
                                <input type="checkbox" class="form-check-input">
                                <i class="input-helper"></i></label>
                              </div>
                            </td>
                            <td>
                              <img src="@/assets/images/orders/order-5.jpg" alt="image" class="img-rect mr-2">
                            </td>
                            <td>
                              <div class="text-dark font-weight-medium">6547-3DESC9835</div>
                            </td>
                            <td>Hero pro cam 7</td>
                            <td>29 Sep 2019</td>
                            <td><label class="badge badge-success">Completed</label></td>
                            <td>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-dots-horizontal"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-grease-pencil"></i></a>
                              <a href="#" class="mr-1 text-muted p-2"><i class="mdi mdi-delete"></i></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex flex-wrap justify-content-between">
                      <h4 class="card-title">Tasks</h4>
                      <b-nav-item-dropdown right noCaret class="preview-list d-none d-md-flex">
                        <template slot="button-content" class="p-0 m-0 d-flex align-items-center">
                          <button class="btn p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-dots-vertical card-menu-btn"></i>
                          </button>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div>
                    <div class="container-fluid">
                      <div class="row ticket-card mt-3 pb-2 border-bottom pb-3 mb-3">
                        <div class="col-md-1">
                          <img class="img-sm rounded-circle mb-4 mb-md-0" src="@/assets/images/faces/face29.jpg" alt="profile image">
                        </div>
                        <div class="ticket-details col-md-9">
                          <div class="d-md-flex">
                            <h4 class="text-dark mr-2 no-wrap">Dustin Lucas</h4>
                            <h5 class="mr-1 text-primary">[#46687]</h5>
                            <p class="font-weight-medium ellipsis">Will The Democrats Be Able To Reverse The Online Gambling Ban</p>
                          </div>
                          <p class="text-gray font-weight-medium">People who have a ticket reservation of the event is automatically mark as interested. Harness The Power Of Words In</p>
                          <div class="row text-muted d-md-flex d-none">
                            <div class="col-12 d-flex">
                              <p class="mb-0 mr-2 text-gray text-small">30 Min ago - Due in 1 days</p>
                            </div>
                          </div>
                        </div>
                        <div class="ticket-actions col-md-2 text-lg-right pr-md-0">
                          <div class="btn-group">
                            <button type="button" class="btn btn-success btn-md d-flex">Action</button>
                          </div>
                        </div>
                      </div>
                      <div class="row ticket-card mt-3 pb-2 border-bottom pb-3 mb-3">
                        <div class="col-md-1">
                          <img class="img-sm rounded-circle mb-4 mb-md-0" src="@/assets/images/faces/face30.jpg" alt="profile image">
                        </div>
                        <div class="ticket-details col-md-9">
                          <div class="d-md-flex">
                            <h4 class="text-dark mr-2 no-wrap">Ida Manning</h4>
                            <h5 class="mr-1 text-primary">[#23135]</h5>
                            <p class="font-weight-medium mb-0 ellipsis">Choosing The Best Audio Player Software For Your Computer</p>
                          </div>
                          <p class="text-muted font-weight-medium">People who have a ticket reservation of the event is automatically mark as interested. Harness The Power Of Words In</p>
                          <div class="row text-muted d-md-flex d-none">
                            <div class="col-12 d-flex">
                              <p class="mb-0 mr-2 text-gray text-small">30 Min ago - Due in 2 days</p>
                            </div>
                          </div>
                        </div>
                        <div class="ticket-actions col-md-2 text-lg-right pr-md-0">
                          <div class="btn-group dropdown">
                            <button type="button" class="btn btn-success btn-md d-flex">Action</button>
                          </div>
                        </div>
                      </div>
                      <div class="row ticket-card mt-3 pb-2 pb-3 mb-3">
                        <div class="col-md-1">
                          <img class="img-sm rounded-circle mb-4 mb-md-0" src="@/assets/images/faces/face31.jpg" alt="profile image">
                        </div>
                        <div class="ticket-details col-md-9">
                          <div class="d-md-flex">
                            <h4 class="text-dark mr-2 no-wrap">Flora Hunter</h4>
                            <h5 class="mr-1 text-primary">[#23135]</h5>
                            <p class="font-weight-medium mb-0 ellipsis">A Discount Toner Cartridge Is Better Than Ever And You Will Save 50 Or More</p>
                          </div>
                          <p class="text-muted mb-2 font-weight-medium">People who have a ticket reservation of the event is automatically mark as interested. Harness The Power Of Words In</p>
                          <div class="row text-muted d-md-flex d-none">
                            <div class="col-12 d-flex">
                              <p class="mb-0 mr-2 text-gray text-small">30 Min ago - Due in 8 days</p>
                            </div>
                          </div>
                        </div>
                        <div class="ticket-actions col-md-2 text-lg-right pr-md-0">
                          <div class="btn-group dropdown">
                            <button type="button" class="btn btn-success btn-md d-flex">Action</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
            Tab Item
          </div>
          <div class="tab-pane fade" id="returns-1" role="tabpanel" aria-labelledby="returns-tab">
            Tab Item
          </div>
          <div class="tab-pane fade" id="more" role="tabpanel" aria-labelledby="more-tab">
            Tab Item
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import revenueOverview from '../../components/charts/dashboard/revenueOverview'
import salesTop from '../../components/charts/dashboard/salesTop'
import budgetBar from '../../components/charts/dashboard/budgetBar'
import salesBar from '../../components/charts/dashboard/salesBar'
import marketing from '../../components/charts/dashboard/marketing'
import purchaseDetails from '../../components/charts/dashboard/purchaseDetails'
import VueSlickCarousel from 'vue-slick-carousel';
import doughnutChart from '../../components/charts/dashboard/doughnutChart'
import doughnutChartUsers from '../../components/charts/dashboard/doughnutChartUsers'
import todoList from '../../components/apps/todoList'
import salesArea from '../../components/charts/dashboard/salesArea'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';



export default {
  name: 'dashboard',
   data () {
    return {
    }
  },
  components: {
     revenueOverview,
     salesTop,
     budgetBar,
     salesBar,
     marketing,
     purchaseDetails,
     VueSlickCarousel,
     doughnutChart,
     doughnutChartUsers,
     todoList,
     salesArea
  },
}
</script>