<script>
import {
  Line
} from 'vue-chartjs'

export default {
  extends: Line,
  data () {
    return {
      datacollection: {
        labels: ["A", "B", "C", "D", "E", "F", "g", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "A", "B", "C", "D", "E", "F", "g", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z","A", "B", "C", "D", "E", "F", "g", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z","T", "U", "V", "W", "X", "Y", "Z"],
        datasets: [{
          label: 'This year',
          data: [56, 56, 55, 58, 58, 57, 56, 55, 55, 56, 56, 57, 57, 57, 58, 58, 58, 56, 56, 56, 56, 56, 58, 58, 57, 55, 54, 54, 54, 56, 56, 56, 55, 55, 53, 53, 53, 53, 55, 55, 55, 56, 56, 56, 55, 56, 56, 56, 55, 54, 54, 56, 56, 56, 57, 57, 57, 57, 55, 55, 56, 56, 54, 54, 54, 54, 56, 57, 57, 55, 55, 56, 56, 57, 57, 58, 59, 58, 58, 58, 56, 56, 58, 58],
          borderColor: [
              '#392ccd'
          ],
          borderWidth: 2.5,
          fill: false,
          backgroundColor:"rgba(255, 255, 255, 1)"
        },
        {
          label: 'Past year',
          data: [26, 26, 25, 28, 28, 27, 26, 25, 25, 26, 26, 27, 27, 27, 28, 28, 28, 26, 26, 26, 26, 26, 28, 28, 27, 25, 24, 24, 24, 26, 26, 26, 25, 25, 23, 23, 23, 23, 25, 25, 25, 26, 26, 26, 25, 26, 26, 26, 25, 24, 24, 26, 26, 26, 27, 27, 27, 27, 25, 25, 26, 26, 24, 24, 24, 24, 26, 27, 27, 25, 25, 26, 26, 27, 27, 28, 29, 28, 28, 28, 26, 26, 28, 28],
          borderColor: [
              '#17c964',
          ],
          borderWidth: 2.5,
          fill: false,
          backgroundColor:"rgba(255, 255, 255, 1)"
        }
      ],
      },
      options: {
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              stepSize: 35,
              beginAtZero: true,
            }
          }],
          xAxes: [{
            display: true,
            position: 'top',
            gridLines: {
            drawBorder: false,
            display: true,
            color:"#ededed",
            zeroLineColor:"#ededed"
            },
            ticks: {
              beginAtZero: true,
              autoSkip: true,
              maxTicksLimit: 12,
              stepSize: 20
            }
          }],

        },
        legend: {
          display: false,
        },
        elements: {
          point: {
            radius: 0
          }
        },
        tooltips: {
          backgroundColor: 'rgba(2, 171, 254, 1)',
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.datacollection, this.options)
  }
}
</script>
